<template>
  <div class="pays">
    <div class="flex flex-center flex-wrap pays-icon">
      <img src="../../../assets/img/order/pay_success.png" alt="">
      <div class="pays-icon-title">支付成功</div>
    </div>
    <div class="pays-info">
      <div class="pays-info-title">恭喜成为灵龙惠生活的种草达人！</div>
      <div class="pays-info-list">
        <div class="pays-info-list-msg">你可以享受达人权益：</div>
        <div class="flex flex-left align-center pays-info-list-content">
          <i></i>专享商品优惠价
        </div>
        <div class="flex flex-left align-center pays-info-list-content">
          <i></i>成功推广灵龙惠生活商品得佣金奖励
        </div>
        <div class="flex flex-left align-center pays-info-list-content">
          <i></i>推荐身边好友成为达人可获得推荐奖励
        </div>
        <div class="flex flex-left align-center pays-info-list-content">
          <i></i>免费定期参加基础新媒体的培训
        </div>
      </div>
    </div>
    <div class="flex flex-between pays-btn">
        <van-button class="pays-btn-list" plain round color="#E62129" @click="goOrderPageFunc">查看订单</van-button>
        <van-button class="pays-btn-list1" round color="#E62129">查看达人推荐商品</van-button>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
  @import "./index.scss";
</style>