export default {
    data() {
        return {

        }
    },
    methods: {
        // 查看订单
        goOrderPageFunc() {
            this.$router.push({
                path: '/orderDetail'
            })
        }
    },
}